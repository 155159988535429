import React, { useEffect, useState } from 'react';
import { BottomNavigation, BottomNavigationAction, Avatar } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ScannerIcon from '@mui/icons-material/QrCodeScanner';
import NoticesIcon from '@mui/icons-material/Notifications';
import BooksIcon from '@mui/icons-material/MenuBook';
import LoginIcon from '@mui/icons-material/AccountCircle';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const Foot = () => {
  const [value, setValue] = useState(0);
  const [user, setUser] = useState(null);
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    let timeoutId;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      // Hide footer if scrolling down, show if scrolling up
      if (currentScrollY > lastScrollY) {
        setIsVisible(false); // Hide footer when scrolling down
      } else {
        setIsVisible(true); // Show footer when scrolling up
      }

      // Update the last scroll position
      setLastScrollY(currentScrollY);

      // Clear the previous timeout
      clearTimeout(timeoutId);

      // Show footer after scrolling stops
      timeoutId = setTimeout(() => {
        setIsVisible(true);
      }, 200); // Adjust the delay as needed
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(timeoutId); // Clear the timeout when the component unmounts
    };
  }, [lastScrollY]);

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleUserAvatarClick = () => {
    if (user) {
      const isAdmin =
        (user.email === process.env.REACT_APP_ADMIN_EMAIL_1 || user.email === "library@suiit.ac.in") &&
        user.emailVerified;
      const isFaculty =
        user.email.endsWith('@suiit.ac.in') && !/\d/.test(user.email) && user.emailVerified;
      const isStudent =
        user.email.endsWith('@suiit.ac.in') && /\d/.test(user.email) && user.emailVerified;

      if (isAdmin) {
        handleNavigation('/admin');
      } else if (isFaculty) {
        handleNavigation('/faculty');
      } else if (isStudent) {
        handleNavigation('/student');
      } else {
        handleNavigation('/login');
      }
    } else {
      handleNavigation('/login');
    }
  };

  return (
    <div
      id='head'
      style={{
        position: 'fixed',
        bottom: isVisible ? 0 : '-100px', // Move footer out of view when hidden
        width: '100%',
        zIndex: 1000,
        backdropFilter: 'blur(10px)', // Blur effect
        boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.28)', // Shadow effect
        transition: 'bottom 0.3s ease-in-out', // Smooth transition
      }}
    >
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
        sx={{
          '& .MuiBottomNavigationAction-root': {
            minWidth: '60px', // Reduce spacing between icons
            padding: '6px', // Add padding for better touch targets
          },
          '& .MuiBottomNavigationAction-label': {
            fontSize: '0.75rem', // Reduce label font size
          },
        }}
      >
        <BottomNavigationAction
          icon={<HomeIcon />}
          onClick={() => handleNavigation('/')}
          sx={{
            '&:hover': {
              color: '#1976d2', // Add hover effect
            },
          }}
        />
        <BottomNavigationAction
          icon={<NoticesIcon />}
          onClick={() => handleNavigation('/notices')}
          sx={{
            '&:hover': {
              color: '#1976d2', // Add hover effect
            },
          }}
        />
        <BottomNavigationAction
          icon={<ScannerIcon />}
          onClick={() => handleNavigation('/scanner')}
          sx={{
            '&:hover': {
              color: '#1976d2', // Add hover effect
            },
          }}
        />
        <BottomNavigationAction
          icon={<BooksIcon />}
          onClick={() => handleNavigation('/books')}
          sx={{
            '&:hover': {
              color: '#1976d2', // Add hover effect
            },
          }}
        />
        <BottomNavigationAction
          icon={
            user ? (
              <Avatar
                src={user.photoURL}
                alt={user.displayName}
                style={{ width: 24, height: 24 }}
              />
            ) : (
              <LoginIcon />
            )
          }
          onClick={handleUserAvatarClick}
          sx={{
            '&:hover': {
              color: '#1976d2', // Add hover effect
            },
          }}
        />
      </BottomNavigation>
    </div>
  );
};

export default Foot;