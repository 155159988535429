import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const LibraryPage = () => {
  return (
    <div className="container mt-5">

      {/* Skeleton for Library Items */}
      <div className="row">
        <div className="col-12 col-md-4 mb-3">
          <div style={{ width: '100%', paddingBottom: '10px' }}>
            <Skeleton height={30} width="100%" />
            <Skeleton height={20} width="60%" />
            <Skeleton height={100} width="100%" style={{ marginTop: '10px' }} />
          </div>
        </div>
        <div className="col-12 col-md-4 mb-3">
          <div style={{ width: '100%', paddingBottom: '10px' }}>
            <Skeleton height={30} width="100%" />
            <Skeleton height={20} width="60%" />
            <Skeleton height={100} width="100%" style={{ marginTop: '10px' }} />
          </div>
        </div>
        <div className="col-12 col-md-4 mb-3">
          <div style={{ width: '100%', paddingBottom: '10px' }}>
            <Skeleton height={30} width="100%" />
            <Skeleton height={20} width="60%" />
            <Skeleton height={100} width="100%" style={{ marginTop: '10px' }} />
          </div>
        </div>
      </div>

      {/* Skeleton for User Profile */}
      <div className="row mt-4">
        <div className="col-12 col-md-4 mb-3">
          <div className="d-flex align-items-center">
            {/* Skeleton for Avatar */}
            <Skeleton circle={true} height={60} width={60} />
            <div className="ml-3">
              {/* Skeleton for User Name */}
              <Skeleton height={20} width="80%" />
              {/* Skeleton for Bio */}
              <Skeleton height={15} width="50%" />
              <Skeleton height={15} width="50%" />
              {/* Skeleton for Email */}
              <Skeleton height={15} width="70%" />
              {/* Skeleton for Address */}
              <Skeleton height={15} width="70%" />
            </div>
          </div>
        </div>
      </div>

      {/* Additional User Information */}
      <div className="row mt-4">
        <div className="col-12 col-md-6 mb-3">
          <div>
            <h4><Skeleton width="30%" /></h4>
            <Skeleton height={40} width="100%" />
            <Skeleton height={40} width="100%" style={{ marginTop: '10px' }} />
          </div>
        </div>
        <div className="col-12 col-md-6 mb-3">
          <div>
            <h4><Skeleton width="30%" /></h4>
            <Skeleton height={40} width="100%" />
            <Skeleton height={40} width="100%" style={{ marginTop: '10px' }} />
          </div>
        </div>
      </div>

      {/* More Skeleton for Profile (Social Links, etc.) */}
      <div className="row mt-4">
        <div className="col-12 col-md-4 mb-3">
          <div>
            <h5><Skeleton width="40%" /></h5>
            <Skeleton height={20} width="80%" />
            <Skeleton height={20} width="80%" />
            <Skeleton height={20} width="80%" />
          </div>
        </div>
        <div className="col-12 col-md-4 mb-3">
          <div>
            <h5><Skeleton width="40%" /></h5>
            <Skeleton height={20} width="80%" />
            <Skeleton height={20} width="80%" />
            <Skeleton height={20} width="80%" />
          </div>
        </div>
      </div>

    </div>
  );
};

export default LibraryPage;
