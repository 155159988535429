import React from 'react';
import { Table } from 'react-bootstrap';

const Sitemap = () => {
  const urls = [
    { loc: "https://library.suiit.ac.in/", lastmod: "2025-01-31T22:42:36+01:00", priority: 0.6 },
    { loc: "https://library.suiit.ac.in/login", lastmod: "2025-01-31T22:42:36+01:00", priority: 1.0 },
    { loc: "https://library.suiit.ac.in/bookS", lastmod: "2025-01-31T22:42:36+01:00", priority: 1.0 },
    { loc: "https://library.suiit.ac.in/BookSoftcopy", lastmod: "2025-01-31T22:42:36+01:00", priority: 1.0 },
    { loc: "https://library.suiit.ac.in/scanner", lastmod: "2025-01-31T22:42:36+01:00", priority: 1.0 },
    { loc: "https://library.suiit.ac.in/contact", lastmod: "2025-01-31T22:42:36+01:00", priority: 1.0 },
    { loc: "https://library.suiit.ac.in/student", lastmod: "2025-01-31T22:42:36+01:00", priority: 1.0 },
    { loc: "https://library.suiit.ac.in/faculty", lastmod: "2025-01-31T22:42:36+01:00", priority: 1.0 },
    { loc: "https://library.suiit.ac.in/admin", lastmod: "2025-01-31T22:42:36+01:00", priority: 1.0 },
    { loc: "https://library.suiit.ac.in/notices", lastmod: "2025-01-31T22:42:36+01:00", priority: 1.0 },
    { loc: "https://library.suiit.ac.in/PrivacyPolicy", lastmod: "2025-01-31T22:42:36+01:00", priority: 1.0 },
    { loc: "https://library.suiit.ac.in/TermsandConditions", lastmod: "2025-01-31T22:42:36+01:00", priority: 1.0 },
    { loc: "https://library.suiit.ac.in/gallery", lastmod: "2025-01-31T22:42:36+01:00", priority: 1.0 },
    { loc: "https://library.suiit.ac.in/ContactUs", lastmod: "2025-01-31T22:42:36+01:00", priority: 1.0 },
    { loc: "https://library.suiit.ac.in/admin/AddBookPage", lastmod: "2025-01-31T22:42:36+01:00", priority: 0.8 },
    { loc: "https://library.suiit.ac.in/required-books", lastmod: "2025-01-31T22:42:36+01:00", priority: 1.0 },
    { loc: "https://library.suiit.ac.in/about", lastmod: "2025-01-31T22:42:36+01:00", priority: 1.0 },
    { loc: "https://library.suiit.ac.in/aboutSUIIT", lastmod: "2025-01-31T22:42:36+01:00", priority: 1.0 },
    { loc: "https://library.suiit.ac.in/support", lastmod: "2025-01-31T22:42:36+01:00", priority: 1.0 },
    { loc: "https://library.suiit.ac.in/profiles", lastmod: "2025-01-31T22:42:36+01:00", priority: 1.0 },
    { loc: "https://library.suiit.ac.in/OSHEC", lastmod: "2025-01-31T22:42:36+01:00", priority: 1.0 },
    { loc: "https://library.suiit.ac.in/FeedbackForm", lastmod: "2025-01-31T22:42:36+01:00", priority: 1.0 },
    { loc: "https://library.suiit.ac.in/libraryEvents", lastmod: "2025-01-31T22:42:36+01:00", priority: 1.0 },
    { loc: "https://library.suiit.ac.in/journals", lastmod: "2025-01-31T22:42:36+01:00", priority: 1.0 },
    { loc: "https://library.suiit.ac.in/admin/AddBook", lastmod: "2025-01-31T22:42:36+01:00", priority: 1.0 },
    { loc: "https://library.suiit.ac.in/admin/AddLibraryEvents", lastmod: "2025-01-31T22:42:36+01:00", priority: 0.8 },
    { loc: "https://library.suiit.ac.in/admin/user-requests", lastmod: "2025-01-31T22:42:36+01:00", priority: 0.8 },
    { loc: "https://library.suiit.ac.in/admin/visitor-details", lastmod: "2025-01-31T22:42:36+01:00", priority: 0.8 },
    { loc: "https://library.suiit.ac.in/admin/add-journals", lastmod: "2025-01-31T22:42:36+01:00", priority: 0.8 },
    { loc: "https://library.suiit.ac.in/CancellationandRefund", lastmod: "2025-01-31T22:42:36+01:00", priority: 1.0 },
    { loc: "https://library.suiit.ac.in/ShippingandDelivery", lastmod: "2025-01-31T22:42:36+01:00", priority: 1.0 },
    { loc: "https://library.suiit.ac.in/admin/issuedBooks", lastmod: "2025-01-31T22:42:36+01:00", priority: 0.8 },
    { loc: "https://library.suiit.ac.in/admin/add-notice", lastmod: "2025-01-31T22:42:36+01:00", priority: 0.8 },
    { loc: "https://library.suiit.ac.in/admin/users", lastmod: "2025-01-31T22:42:36+01:00", priority: 0.8 },
    { loc: "https://library.suiit.ac.in/admin/AddToGallery", lastmod: "2025-01-31T22:42:36+01:00", priority: 0.8 },
    { loc: "https://library.suiit.ac.in/admin/AddBookSoftware", lastmod: "2025-01-31T22:42:36+01:00", priority: 0.8 },
    { loc: "https://library.suiit.ac.in/faculty/FacultyAddBookSoftware", lastmod: "2025-01-31T22:42:36+01:00", priority: 0.8 },
    { loc: "https://library.suiit.ac.in/BarcodeGenerator", lastmod: "2025-01-31T22:42:36+01:00", priority: 1.0 },
    { loc: "https://library.suiit.ac.in/ProfileManager", lastmod: "2025-01-31T22:42:36+01:00", priority: 1.0 },
    { loc: "https://library.suiit.ac.in/Sitemap", lastmod: "2025-01-31T22:42:36+01:00", priority: 1.0 }
  ];

  return (
    <div className="container mt-5">
      <h1 className='mb-4 text-center'>Sitemap</h1>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>URL</th>
            <th>Last Modified</th>
            <th>Priority</th>
          </tr>
        </thead>
        <tbody>
          {urls.map((url, index) => (
            <tr key={index}>
              <td><a href={url.loc} target="_blank" rel="noopener noreferrer">{url.loc}</a></td>
              <td>{url.lastmod}</td>
              <td>{url.priority}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default Sitemap;
