import React, { useState } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Box,
  Typography,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import LogoutIcon from '@mui/icons-material/Logout';
import InfoIcon from '@mui/icons-material/Info';
import SchoolIcon from '@mui/icons-material/School';
import BookIcon from '@mui/icons-material/Book';
import PeopleIcon from '@mui/icons-material/People'; // Icon for Staff List
import WorkIcon from '@mui/icons-material/Work'; // Icon for OSHEC
import EventIcon from '@mui/icons-material/Event';
import FeedbackIcon from '@mui/icons-material/Feedback';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import { Link, useLocation } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebaseConfig'; // Ensure you have firebase configured

const GSlidebar = () => {
  const [user] = useAuthState(auth);
  const [open, setOpen] = useState(false);
  const location = useLocation(); // To highlight the active route

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(open);
  };

  const isAdmin = (user?.email === process.env.REACT_APP_ADMIN_EMAIL_1 || user?.email === "library@suiit.ac.in") && user?.emailVerified;
  const isFaculty = user?.email.endsWith('@suiit.ac.in') && !/\d/.test(user?.email) && user?.emailVerified;
  const isStudent = user?.email.endsWith('@suiit.ac.in') && /\d/.test(user?.email) && user?.emailVerified;

  const handleLogout = async () => {
    await auth.signOut();
  };

  const list = () => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      style={{ width: 250}}
      
    >
      {/* User Profile Section */}
      {user ? (
        <>
        
        {isFaculty && (
              <>
                <ListItem button component={Link} to="/faculty">
                <Box
  sx={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Center horizontally
    justifyContent: 'center', // Center vertically
    pl: 5,
    textAlign: 'center',
    color: 'black',
  }}
>
  <img
    src={user.photoURL}
    alt="Profile"
    style={{ width: 80, height: 80, borderRadius: '50%', marginBottom: 1 }}
  />
  <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 1 , color: 'black', }}>
    {user.displayName}
  </Typography>
  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
    {user.email}
  </Typography>
  <hr style={{ width: "100%", border: "1px solid rgb(164, 164, 164)" }} />
</Box>
                </ListItem>
                
              </>
            )}

            {/* Student Links */}
            {isStudent && (
              <>
              <ListItem button component={Link} to="/student">
              <Box
  sx={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Center horizontally
    justifyContent: 'center', // Center vertically
    pl: 5,
    textAlign: 'center',
    color: 'black',
  }}
>
  <img
    src={user.photoURL}
    alt="Profile"
    style={{ width: 80, height: 80, borderRadius: '50%', marginBottom: 1 }}
  />
  <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 1, color: 'black', }}>
    {user.displayName}
  </Typography>
  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
    {user.email}
  </Typography>
  <hr style={{ width: "100%", border: "1px solid rgb(164, 164, 164)" }} />
</Box>


</ListItem>

                {isAdmin && (
              <>
                <ListItem button component={Link} to="/admin">
                  <ListItemIcon><img src={user.photoURL} alt="Admin" style={{ width: 24, height: 24, borderRadius: '50%' }} /></ListItemIcon>
                  <ListItemText primary="Admin" />
                </ListItem>
              </>
            )}

           
              </>
              
            )}
             
          </>
        ): <ListItem
  button
  component={Link}
  to="/login"
  sx={{
    backgroundColor: location.pathname === '/login' ? '#e3f2fd' : 'inherit',
    '&:hover': { backgroundColor: '#f5f5f5' },
  }}
>
  <ListItemIcon>
    <AccountCircleIcon /> {/* Replaced with User Icon */}
  </ListItemIcon>
  <ListItemText primary="Login" />
</ListItem>

        }
    

      <List>
        {/* General Links */}
        <ListItem
          button
          component={Link}
          to="/"
          sx={{
            backgroundColor: location.pathname === '/' ? '#e3f2fd' : 'inherit',
            '&:hover': { backgroundColor: '#f5f5f5' },
          }}
        >
          <ListItemIcon><HomeIcon /></ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/BookSoftcopy"
          sx={{
            backgroundColor: location.pathname === '/BookSoftcopy' ? '#e3f2fd' : 'inherit',
            '&:hover': { backgroundColor: '#f5f5f5' },
          }}
        >
          <ListItemIcon><LibraryBooksIcon /></ListItemIcon>
          <ListItemText primary="E-Books" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/journals"
          sx={{
            backgroundColor: location.pathname === '/journals' ? '#e3f2fd' : 'inherit',
            '&:hover': { backgroundColor: '#f5f5f5' },
          }}
        >
          <ListItemIcon><BookIcon /></ListItemIcon>
          <ListItemText primary="Journals" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/books"
          sx={{
            backgroundColor: location.pathname === '/books' ? '#e3f2fd' : 'inherit',
            '&:hover': { backgroundColor: '#f5f5f5' },
          }}
        >
          <ListItemIcon><MenuBookIcon /></ListItemIcon>
          <ListItemText primary="Library Books" />
        </ListItem>

        <ListItem
  button
  component={Link}
  to="/profiles"
  sx={{
    backgroundColor: location.pathname === '/profiles' ? '#e3f2fd' : 'inherit',
    '&:hover': { backgroundColor: '#f5f5f5' },
  }}
>
  <ListItemIcon>
    <PeopleIcon  /> {/* Icon for Staff List */}
  </ListItemIcon>
  <ListItemText primary="Staff List" />
</ListItem>

<ListItem
  button
  component={Link}
  to="/OSHEC"
  sx={{
    backgroundColor: location.pathname === '/OSHEC' ? '#e3f2fd' : 'inherit',
    '&:hover': { backgroundColor: '#f5f5f5' },
  }}
>
  <ListItemIcon>
    <WorkIcon /> {/* Icon for OSHEC */}
  </ListItemIcon>
  <ListItemText primary="OSHEC" />
</ListItem>

        {user&&(  <ListItem button component={Link} to="/required-books">
                  <ListItemIcon><LibraryBooksIcon /></ListItemIcon>
                  <ListItemText primary="Required Books" />
                </ListItem>)}

        <ListItem
          button
          component={Link}
          to="/libraryEvents"
          sx={{
            backgroundColor: location.pathname === '/libraryEvents' ? '#e3f2fd' : 'inherit',
            '&:hover': { backgroundColor: '#f5f5f5' },
          }}
        >
          <ListItemIcon><EventIcon /></ListItemIcon>
          <ListItemText primary="Library Events" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/about"
          sx={{
            backgroundColor: location.pathname === '/about' ? '#e3f2fd' : 'inherit',
            '&:hover': { backgroundColor: '#f5f5f5' },
          }}
        >
          <ListItemIcon><InfoIcon /></ListItemIcon>
          <ListItemText primary="About Library" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/aboutSUIIT"
          sx={{
            backgroundColor: location.pathname === '/aboutSUIIT' ? '#e3f2fd' : 'inherit',
            '&:hover': { backgroundColor: '#f5f5f5' },
          }}
        >
          <ListItemIcon><SchoolIcon /></ListItemIcon>
          <ListItemText primary="About SUIIT" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/gallery"
          sx={{
            backgroundColor: location.pathname === '/gallery' ? '#e3f2fd' : 'inherit',
            '&:hover': { backgroundColor: '#f5f5f5' },
          }}
        >
          <ListItemIcon><CollectionsBookmarkIcon /></ListItemIcon>
          <ListItemText primary="Gallery" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/support"
          sx={{
            backgroundColor: location.pathname === '/support' ? '#e3f2fd' : 'inherit',
            '&:hover': { backgroundColor: '#f5f5f5' },
          }}
        >
          <ListItemIcon><ContactMailIcon /></ListItemIcon>
          <ListItemText primary="Support & Help" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/FeedbackForm"
          sx={{
            backgroundColor: location.pathname === '/FeedbackForm' ? '#e3f2fd' : 'inherit',
            '&:hover': { backgroundColor: '#f5f5f5' },
          }}
        >
          <ListItemIcon><FeedbackIcon /></ListItemIcon>
          <ListItemText primary="Book Feedback" />
        </ListItem>

        {/* Logout Button */}
        {user && (
          <ListItem
            button
            onClick={handleLogout}
            sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}
          >
            <ListItemIcon><LogoutIcon /></ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        )}
      </List>
    </div>
  );

  return (
    <div>
      <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
        {user ? (
          <img
            src={user.photoURL}
            alt="User"
            style={{ width: 24, height: 24, borderRadius: '50%' }}
          />
        ) : (
          <i className="bi bi-grid-3x3-gap" style={{ fontSize: '16px', fontWeight: 'bold', color: 'white' }}></i>
        )}
      </IconButton>

      <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </div>
  );
};

export default GSlidebar;