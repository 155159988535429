import React, { useState, useEffect, useRef } from "react";
import { Link,  } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import styles from "./css/HomeNavbar.module.css";
import './css/HomeNavbar.css';
import { auth } from '../firebaseConfig';
import SearchButton from './SearchButton';
import GSlidebar from './GSlidebar'


const CustomNavbar = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const navbarRef = useRef(null);
  const [user, setUser] = useState(null);
  const handleToggle = () => setIsExpanded(!isExpanded);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);





  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  
  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      setUser(auth.currentUser);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setIsExpanded(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        navbarRef.current.classList.add(styles.navbarShadow);
      } else {
        navbarRef.current.classList.remove(styles.navbarShadow);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

 


  return (
    <Navbar
      className={`${styles.customNavbar}`}
      expand="lg"
      fixed="top"
      ref={navbarRef}
      expanded={isExpanded}
      style={{
    
    padding: isExpanded ? '10px 0' : '0px 0', // Adjust the padding based on the expanded state
    transition: 'padding 0.3s ease', // Smooth transition
    background: 'linear-gradient(135deg, rgba(0, 0, 0, 0.82), rgba(0, 0, 0, 0.92))', // Semi-transparent blue-black gradient
    zIndex: 1050,
    backdropFilter: "blur(10px)", // Adds the blur effect
    WebkitBackdropFilter: "blur(10px)", // For Safari support
    boxShadow:'0 1px 5px rgb(0,0,0) ,0 2px 10px rgb(0, 0, 0)',

  }}
      
      >
      
    <div className="container-fluid" >
    <GSlidebar/>
      <Navbar.Toggle
        onClick={handleToggle}
        aria-controls="basic-navbar-nav"
        style={{
          border: 'none',
          backgroundColor: 'transparent',
          padding: '0px',
          marginLeft: '-10px',
          display:'none'
        }}
      >
        <i className="bi bi-list" style={{ color: 'white', fontSize: '1.8rem' }}></i>
      </Navbar.Toggle>
  
      <Nav className={`me-auto p-0 m-0 ${styles.navbarLinks}`} id="mob">
       
          <Nav.Link
            style={{
              color: 'white',
              fontSize: '0.9rem',
              fontWeight: 'bold',
              paddingLeft: '2px',
              textAlign: 'center',
            }}
            as={Link} to="/"
          >
           <div className="d-flex align-items-center" id="head">
  
  <span
    style={{ fontSize: '1rem', color: 'white', marginLeft: '8px' }}
    id="head"  // Adjust the text size and spacing
  >
  SUIIT Library
  </span>
</div>


          </Nav.Link>
          
      </Nav>
      <div style={{ display: 'flex', alignItems: 'center', gap: '13px',paddingRight:'20px'}}>
      <SearchButton />
  
</div>


       
          
     
          
              
             

      <Navbar.Collapse id="basic-navbar-nav" >
        <NavDropdown
          title={<span><i className="bi bi-house-door me-2"></i>Home</span>}
          id="institute-links-dropdown"
          className="me-3"
        >
          <NavDropdown.Item as={Link} to="./">
            <i className="bi bi-house-door me-2"></i>Home
          </NavDropdown.Item>
   <div className="dropdown-divider"></div>
   <NavDropdown.Item as={Link} to="/about">
               <i className="bi bi-info-circle me-2"></i>About Library
               </NavDropdown.Item>
               <div className="dropdown-divider"></div>
    
               <NavDropdown.Item as={Link} to="/aboutSUIIT">
               <i className="bi bi-info-circle me-2"></i>About SUIIT
               </NavDropdown.Item>
    
                      <div className="dropdown-divider"></div>
                      <NavDropdown.Item as={Link} to="https://suiit.ac.in/institute/suiit-photos">
            <i className="bi bi-image-fill me-2"></i> SUIIT Gallery
          </NavDropdown.Item>
          <div className="dropdown-divider"></div>
          <NavDropdown.Item as={Link} to="/gallery">
            <i className="bi bi-image me-2"></i> Library Gallery
          </NavDropdown.Item>
          <div className="dropdown-divider"></div>
          <NavDropdown.Item as={Link} to="/contact">
            <i className="bi bi-globe me-2"></i> Contact Us
          </NavDropdown.Item>
          <div className="dropdown-divider"></div>
          <NavDropdown.Item as={Link} to="/support">
          <i className="bi bi-life-preserver me-3"></i> Support & Help
          </NavDropdown.Item>
        
          {!user ? (
            <NavDropdown.Item as={Link} to="./login">
              <div className="dropdown-divider"></div>
              <i className="bi bi-box-arrow-in-right me-2"></i> Login
            </NavDropdown.Item>
          ) : null}
        </NavDropdown>
  
       <div
          className="me-2"
          id="hide"
          style={{
            width: '2px',
            backgroundColor: 'rgb(0, 0, 0)',
            height: '35px',
            margin: '0 0px',
          }}
        ></div>
  
        <NavDropdown
          title={<span><i className="bi bi-building me-2"></i> Institute Links</span>}
          id="institute-links-dropdown"
          className="me-3"
        >
          <NavDropdown.Item as={Link} to="https://suiit.ac.in/admission">
            <i className="bi bi-person-check-fill me-2"></i> Admission
          </NavDropdown.Item>
          <div className="dropdown-divider"></div>
          <NavDropdown.Item as={Link} to="https://suiit.ac.in/placement">
            <i className="bi bi-briefcase-fill me-2"></i> Placement
          </NavDropdown.Item>
          <div className="dropdown-divider"></div>
          <NavDropdown.Item as={Link} to="https://suiit.ac.in/institute/suiit-faculty">
            <i className="bi bi-person-lines-fill me-2"></i> Faculty List
          </NavDropdown.Item>
           <div className="dropdown-divider"></div>
                  <NavDropdown.Item as={Link} to="/profiles">
          <i className="bi bi-person-lines-fill me-2"></i> Staff List-Profile
          </NavDropdown.Item>
          <div className="dropdown-divider"></div>
          <NavDropdown.Item as={Link} to="https://suiit.ac.in/institute/committee/anti-ragging">
            <i className="bi bi-shield-lock-fill me-2"></i> Anti Ragging
          </NavDropdown.Item>
          
        </NavDropdown>
 
       <div
          className="me-2"
          id="hide"
          style={{
            width: '2px',
            backgroundColor: 'rgb(0, 0, 0)',
            height: '35px',
            margin: '0 0px',
          }}
        ></div>
  
        <NavDropdown
          title={<span><i className="bi bi-book me-2"></i>E-Resources</span>}
          id="student-resources-dropdown"
          className="me-3"
        >
          <NavDropdown.Item as={Link} to="/notices">
            <i className="bi bi-file-earmark-text me-2"></i> Notices
          </NavDropdown.Item>
          
          <div className="dropdown-divider"></div>
          <NavDropdown.Item as={Link} to="/BookSoftcopy">
            <i className="bi bi-book me-2"></i> E-Book
          </NavDropdown.Item>
          <div className="dropdown-divider"></div>
  
          <NavDropdown.Item as={Link} to="/bookS">
            <i className="bi bi-book-half me-2"></i> Library Book
          </NavDropdown.Item>
          <div className="dropdown-divider"></div>
          <NavDropdown.Item as={Link} to="/required-books">
            <i className="bi bi-journal-plus me-2"></i> Request Book
          </NavDropdown.Item>
          <div className="dropdown-divider"></div>
          <NavDropdown.Item as={Link} to="/scanner">
            <i className="bi bi-qr-code me-2"></i> Scanner
          </NavDropdown.Item>
           <div className="dropdown-divider"></div>
            <NavDropdown.Item as={Link} to="/OSHEC">
              <i className="bi bi-at me-2"></i>OSHEC
            </NavDropdown.Item>
        </NavDropdown>
  
       <div
          className="me-2"
          id="hide"
          style={{
            width: '2px',
            backgroundColor: 'rgb(0, 0, 0)',
            height: '35px',
            margin: '0 0px',
          }}
        ></div>

        <NavDropdown
          title={<span><i className="bi bi-search me-2"></i> OPAC</span>}
          id="student-resources-dropdown"
          className="me-3" 
        >
          
          <NavDropdown.Item as={Link} to="/bookS">
            <i className="bi bi-search me-2"></i> Book Search
          </NavDropdown.Item>
          <div className="dropdown-divider"></div>
        
          <NavDropdown.Item as={Link} to="/Student">
            <i className="bi bi-bookmark-fill me-2"></i> Reserve Book
          </NavDropdown.Item>
          <div className="dropdown-divider"></div>
        
          <NavDropdown.Item as={Link} to="/FeedbackForm">
            <i className="bi bi-chat-left-text me-2"></i> Book Feedback
          </NavDropdown.Item>
          <div className="dropdown-divider"></div>
        
          <NavDropdown.Item as={Link} to="/libraryEvents">
            <i className="bi bi-calendar-event me-2"></i> Library Events
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/journals">
          <div className="dropdown-divider"></div>
            <i className="bi bi-file-earmark-text me-2"></i>Library Journals
            </NavDropdown.Item>
            <div className="dropdown-divider"></div>
            <NavDropdown.Item as={Link} to="https://suiit.ac.in/research/publications">
            <i className="bi bi-file-earmark-text me-2"></i>SUIIT Journals
            </NavDropdown.Item>
        </NavDropdown>
        
  
        <NavDropdown
          title={<span><i className="bi bi-file-earmark-lock me-2"></i> Documents & Policies</span>}
          id="documents-policies"
          className="me-3"
        >
          <NavDropdown.Item as={Link} to="https://drive.google.com/file/d/1X3b2i2CkuofXmRmXQ3Mp3_YT9IxgMjX4/view?usp=sharing">
            <i className="bi bi-file-earmark-text me-2"></i> Information Brochure
          </NavDropdown.Item>
          <div className="dropdown-divider"></div>
          <NavDropdown.Item as={Link} to="/PrivacyPolicy">
            <i className="bi bi-file-earmark-lock me-2"></i> Privacy Policy
          </NavDropdown.Item>
          <div className="dropdown-divider"></div>
          <NavDropdown.Item as={Link} to="/TermsandConditions">
            <i className="bi bi-file-earmark-text me-2"></i> Terms & Conditions
          </NavDropdown.Item>
          <div className="dropdown-divider"></div>
          <NavDropdown.Item as={Link} to="https://suiit.ac.in/rti-cell">
            <i className="bi bi-file-earmark-ruled me-2"></i> RTI
          </NavDropdown.Item>
        </NavDropdown>     
        <NavDropdown
                  title={<span> <i className="bi bi-file-earmark-text me-2"></i> Notices</span>}
                  id="student-resources-dropdown"
                  className="me-3"
                >
                  <NavDropdown.Item as={Link} to="/notices">
                  <i className="bi bi-file-earmark-text me-2"></i> Notices
                  </NavDropdown.Item>
                  <div className="dropdown-divider"></div>
                  <NavDropdown.Item as={Link} to="https://suiit.ac.in/archives/category/notice">
                  <i className="bi bi-file-earmark-text me-2"></i> SUIIT Notices
                  </NavDropdown.Item>
                  
                </NavDropdown>
                
      </Navbar.Collapse>
    </div>
  
  </Navbar>
  
  );
};

export default CustomNavbar;
