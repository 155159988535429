import React, { useEffect, useState } from 'react';
import { fetchTotalVisitorCount } from './visitorTracker';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import SUIITLOGO from './images/SUIITLOGO.png';
import SvgHader from './svg/1.svg';



const Footer = () => {
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    const getVisitorCount = async () => {
      const count = await fetchTotalVisitorCount();
      setTotalCount(count);
    };
    getVisitorCount();
  }, []);


    

  return (
      <footer  style={{ backgroundImage: `url(${SvgHader})`, backgroundSize: 'cover',  
    backgroundRepeat: 'no-repeat',
    borderRadius: "10px",
    boxShadow: "0 1px 15px rgb(0, 0, 0)",
    margin: "0 auto",
    height: "auto",
    backdropFilter: "blur(5px)", // Adds the blur effect
    WebkitBackdropFilter: "blur(5px)", // For Safari support
    }}>
    
      <div 
      className="p-3"
      style={{
    borderRadius: "10px",
    backgroundColor: "rgba(0, 0, 0, 0.28)",
    boxShadow: "0 4px 15px rgb(0, 0, 0)",
    margin: "0 auto",
    height: "auto",
    backdropFilter: "blur(5px)", // Adds the blur effect
    WebkitBackdropFilter: "blur(5px)", // For Safari support
  }}>
        


        {/* Footer Content Sections */}
        <div className="row">
          {/* Logo and Institution Information */}
          <div className="col-md-3 mb-4">
            <img 
              src={SUIITLOGO} 
              alt="SUIIT Logo" 
              className="mb-3" 
              style={{
                maxWidth: '120px',
                backgroundColor: 'transparent',
              }} 
            />
            <ul className="list-unstyled">
              <li><h4><Link to="/" className="text-decoration-none text-light">SUIIT</Link></h4></li>
              <li><p className="text-light">Sambalpur University Institute of Information Technology, Jyotivihar, Burla, 768019</p></li>
              <li><p className="text-light"><i className="bi bi-telephone-fill me-2"></i>+91 81443346426</p></li>
              <li><p className="text-light"><i className="bi bi-globe me-2"></i>www.library.suiit.ac.in</p></li>
            </ul>
          
        {/* Bar Chart Icon with Green Color */}
        
       
      
        <div className="d-flex justify-content-center align-items-center my-4">
  <a
    href="https://www.facebook.com/suiit.official/"
    target="_blank"
    rel="noopener noreferrer"
    className="mx-3"
    style={{ color: '#3b5998', fontSize: '2.2rem', transition: 'transform 0.3s ease' }}
    onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
    onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
  >
    <i className="bi bi-facebook"></i>
  </a>
  <a
    href="https://www.linkedin.com/school/sambalpur-university-institute-of-information-technology/?originalSubdomain=in"
    target="_blank"
    rel="noopener noreferrer"
    className="mx-3"
    style={{ color: '#0a66c2', fontSize: '2.2rem', transition: 'transform 0.3s ease' }}
    onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
    onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
  >
    <i className="bi bi-linkedin"></i>
  </a>
  <a
    href="https://x.com/suiit_official"
    target="_blank"
    rel="noopener noreferrer"
    className="mx-3"
    style={{ fontSize: '2.2rem', color: '#046655', transition: 'transform 0.3s ease', marginTop:'-10px'}}
    onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
    onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1.0)' )}
  >
  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="42" height="42" viewBox="0 0 50 50" >
<path d="M 11 4 C 7.134 4 4 7.134 4 11 L 4 39 C 4 42.866 7.134 46 11 46 L 39 46 C 42.866 46 46 42.866 46 39 L 46 11 C 46 7.134 42.866 4 39 4 L 11 4 z M 13.085938 13 L 21.023438 13 L 26.660156 21.009766 L 33.5 13 L 36 13 L 27.789062 22.613281 L 37.914062 37 L 29.978516 37 L 23.4375 27.707031 L 15.5 37 L 13 37 L 22.308594 26.103516 L 13.085938 13 z M 16.914062 15 L 31.021484 35 L 34.085938 35 L 19.978516 15 L 16.914062 15 z"></path>
</svg>
   
  </a>
  <a
    href="https://www.instagram.com/suiit.official/?hl=en"
    target="_blank"
    rel="noopener noreferrer"
    className="mx-3"
    style={{ color: '#e1306c', fontSize: '2.2rem', transition: 'transform 0.3s ease' }}
    onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
    onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
  >
    <i className="bi bi-instagram"></i>
  </a>
</div>
        <i className="bi bi-bar-chart-fill me-2" style={{ fontSize: '1.5rem', color: 'rgb(26, 255, 0)' }}></i>
        <span style={{ color: 'rgb(255, 255, 255)' }}>
          <strong>Visitors:</strong> {totalCount}
        </span> 
        <i className="bi bi-plus-circle-fill ms-2" style={{ fontSize: '1.5rem', color: 'rgb(0, 229, 255)' }}></i>
        </div>
    

          <div className="col-md-3 mb-4">
  <h5 className="text-light mb-3" style={{ textAlign: 'left' }}>
    <i className="fas fa-link"></i> Quick Links
  </h5>

  <ul className="list-unstyled" style={{ paddingLeft: '0'  }}>
    <li>
      <Link to="https://drive.google.com/file/d/1X3b2i2CkuofXmRmXQ3Mp3_YT9IxgMjX4/view?usp=sharing" 
            className="text-decoration-none text-light link-hover" 
            style={{ display: 'flex', alignItems: 'center' }}>
        <i className="bi bi-file-earmark-text me-2"></i> Information Brochure
        <i className="bi bi-arrow-right ms-auto arrow"></i>
      </Link>
    </li>
    <li>
      <Link to="https://suiit.ac.in/admission" 
            className="text-decoration-none text-light link-hover" 
            style={{ display: 'flex', alignItems: 'center' }}>
        <i className="bi bi-person-check-fill me-2"></i> Admission
        <i className="bi bi-arrow-right ms-auto arrow"></i>
      </Link>
    </li>
    <li>
      <Link to="https://suiit.ac.in/placement" 
            className="text-decoration-none text-light link-hover" 
            style={{ display: 'flex', alignItems: 'center' }}>
        <i className="bi bi-briefcase-fill me-2"></i> Placement
        <i className="bi bi-arrow-right ms-auto arrow"></i>
      </Link>
    </li>
    <li>
      <Link to="https://suiit.ac.in/institute/suiit-faculty" 
            className="text-decoration-none text-light link-hover" 
            style={{ display: 'flex', alignItems: 'center' }}>
        <i className="bi bi-person-lines-fill me-2"></i> Faculty List
        <i className="bi bi-arrow-right ms-auto arrow"></i>
      </Link>
    </li>
    <li>
      <Link to="https://suiit.ac.in/institute/committee/anti-ragging" 
            className="text-decoration-none text-light link-hover" 
            style={{ display: 'flex', alignItems: 'center' }}>
        <i className="bi bi-shield-lock-fill me-2"></i> Anti Ragging
        <i className="bi bi-arrow-right ms-auto arrow"></i>
      </Link>
    </li>
    <li>
      <Link to="https://suiit.ac.in/infrastructure/hostel-rules-fees" 
            className="text-decoration-none text-light link-hover" 
            style={{ display: 'flex', alignItems: 'center' }}>
        <i className="bi bi-house-door-fill me-2"></i> Hostel
        <i className="bi bi-arrow-right ms-auto arrow"></i>
      </Link>
    </li>
    <li>
      <Link to="https://suiit.ac.in/campus-life" 
            className="text-decoration-none text-light link-hover" 
            style={{ display: 'flex', alignItems: 'center' }}>
        <i className="bi bi-tree-fill me-2"></i> Campus Life
        <i className="bi bi-arrow-right ms-auto arrow"></i>
      </Link>
    </li>
    <li>
      <Link to="https://suiit.ac.in/institute/suiit-photos" 
            className="text-decoration-none text-light link-hover" 
            style={{ display: 'flex', alignItems: 'center' }}>
        <i className="bi bi-image-fill me-2"></i> Photo Gallery
        <i className="bi bi-arrow-right ms-auto arrow"></i>
      </Link>
    </li>
    <li>
      <Link to="https://suiit.ac.in/rti-cell" 
            className="text-decoration-none text-light link-hover" 
            style={{ display: 'flex', alignItems: 'center' }}>
        <i className="bi bi-file-earmark-ruled me-2"></i> RTI
        <i className="bi bi-arrow-right ms-auto arrow"></i>
      </Link>
    </li>

    {/* Additional Links */}
    <li>
      <Link to="/notices" 
            className="text-decoration-none text-light link-hover" 
            style={{ display: 'flex', alignItems: 'center' }}>
        <i className="bi bi-file-earmark-text me-2"></i> Notices
        <i className="bi bi-arrow-right ms-auto arrow"></i>
      </Link>
    </li>
    <li>
      <Link to="/BookSoftcopy" 
            className="text-decoration-none text-light link-hover" 
            style={{ display: 'flex', alignItems: 'center' }}>
        <i className="bi bi-book me-2"></i> E-Book
        <i className="bi bi-arrow-right ms-auto arrow"></i>
      </Link>
    </li>
    <li>
      <Link to="/PrivacyPolicy" 
            className="text-decoration-none text-light link-hover" 
            style={{ display: 'flex', alignItems: 'center' }}>
        <i className="bi bi-file-earmark-lock me-2"></i> Privacy Policy
        <i className="bi bi-arrow-right ms-auto arrow"></i>
      </Link>
    </li>
    <li>
      <Link to="/TermsandConditions" 
            className="text-decoration-none text-light link-hover" 
            style={{ display: 'flex', alignItems: 'center' }}>
        <i className="bi bi-file-earmark-text me-2"></i> Terms & Conditions
        <i className="bi bi-arrow-right ms-auto arrow"></i>
      </Link>
    </li>
    <li>
      <Link to="/gallery" 
            className="text-decoration-none text-light link-hover" 
            style={{ display: 'flex', alignItems: 'center' }}>
        <i className="bi bi-image me-2"></i> Gallery
        <i className="bi bi-arrow-right ms-auto arrow"></i>
      </Link>
    </li>
  </ul>
</div>


          {/* Contact Information */}
          <div className="col-md-3 mb-4">
            <h5 className="text-light mb-3">Contact Information</h5>
            <ul className="list-unstyled">
              <li><Link to="/ContactUs" className="text-decoration-none text-light"><i className="bi bi-envelope me-2"></i> Contact Us</Link></li>
              <li><p className="text-light">SUIIT, Jyotivihar, Burla, 768019</p></li>
              <li><p className="text-light"><i className="bi bi-envelope-fill me-2"></i><a href="mailto:library@suiit.ac.in" className="text-light">library@suiit.ac.in</a></p></li>
              <li><div className="map-container"
      style={{
        
        backgroundColor: 'transparent',
        position: 'relative',
        overflow: 'hidden',
        borderRadius: '1px', // Smooth corners
        transition: 'transform 0.5s ease, box-shadow 0.5s ease', // Smooth transition for scale and shadow
        animation: 'float 5s ease-in-out infinite', // Continuous floating animation
      }}
    >
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d152987.89685159255!2d83.88058624586034!3d21.473493652851896!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a213c368309af57%3A0xdcd4d19c95624841!2sSambalpur%20University%20Institute%20of%20Information%20Technology%20(SUIIT)%2C%20Burla!5e0!3m2!1sen!2sus!4v1730644142323!5m2!1sen!2sus"
        width="100%"
        height="270"
        style={{
          border: '0',
          borderRadius: '8px',
          transition: 'transform 0.3s ease', // Smooth transition for iframe itself
        }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Sambalpur University Location"
        onMouseEnter={e => e.target.style.transform = 'scale(1.02)'}
        onMouseLeave={e => e.target.style.transform = 'scale(1)'}
      ></iframe>
    </div></li>
              
            </ul>
            
          </div>

          {/* Gallery Section */}
     
          <div className="col-md-3 mb-1 text-center">
          <Link to="/gallery" 
            className="text-decoration-none text-light link-hover" 
            style={{  alignItems: 'center' }}>
          <h5 className="text-light text-center mb-3">
  <i className="bi bi-image me-2"></i> Gallery
</h5>

  <div id="ftrglry" className="row g-2">
    {/* Gallery Images with enhanced styling */}
    <div className="ftrimg col-4 m-2" style={{
      backgroundImage: "url('https://suiit.ac.in/wp-content/uploads/2023/08/suiit_building_image_2_197x115.jpg')",
      minHeight: '115px', 
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      borderRadius: '12px', // Slightly more rounded
      padding: '5px', 
      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' 
    }}
      
      onMouseEnter={e => e.target.style.transform = 'scale(1.05)'}
      onMouseLeave={e => e.target.style.transform = 'scale(1)'}
    ></div>
    <div className="ftrimg col-4 m-2" style={{
      backgroundImage: "url('https://suiit.ac.in/wp-content/uploads/2023/08/suiit-img-8_155x115.jpg')",
      minHeight: '115px', 
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      borderRadius: '12px', // Slightly more rounded
      padding: '5px', 
      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' 
    }}
      
      onMouseEnter={e => e.target.style.transform = 'scale(1.05)'}
      onMouseLeave={e => e.target.style.transform = 'scale(1)'}
    ></div>
    <div className="ftrimg col-4 m-2" style={{
      backgroundImage: "url('https://suiit.ac.in/wp-content/uploads/2023/08/suiit-img-3_153x115.jpg')",
      minHeight: '115px', 
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      borderRadius: '12px',
      padding: '5px',
      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' 
    }}
      
      onMouseEnter={e => e.target.style.transform = 'scale(1.05)'}
      onMouseLeave={e => e.target.style.transform = 'scale(1)'}
    ></div>
    <div className="ftrimg col-4 m-2" style={{
      backgroundImage: "url('https://suiit.ac.in/wp-content/uploads/2023/08/suiit-img-4_153x115.jpg')",
      minHeight: '115px', 
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      borderRadius: '12px',
      padding: '5px',
      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' 
    }}
      
      onMouseEnter={e => e.target.style.transform = 'scale(1.05)'}
      onMouseLeave={e => e.target.style.transform = 'scale(1)'}
    ></div>
    <div className="ftrimg col-4 m-2" style={{
      backgroundImage: "url('https://suiit.ac.in/wp-content/uploads/2023/08/suiit-img-5_153x115.jpeg')",
      minHeight: '115px', 
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      borderRadius: '12px',
      padding: '5px',
      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' 
    }}
      
      onMouseEnter={e => e.target.style.transform = 'scale(1.05)'}
      onMouseLeave={e => e.target.style.transform = 'scale(1)'}
    ></div>
    <div className="ftrimg col-4 m-2" style={{
      backgroundImage: "url('https://suiit.ac.in/wp-content/uploads/2023/08/suiit-img-7_115x153.jpg')",
      minHeight: '115px', 
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      borderRadius: '12px',
      padding: '5px',
      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' 
    }}
      
      onMouseEnter={e => e.target.style.transform = 'scale(1.05)'}
      onMouseLeave={e => e.target.style.transform = 'scale(1)'}
    ></div>
  </div>
  </Link>
</div>


        </div>

    



        {/* Footer Text */}
        <div className="text-center">
          <p className="text-light">© {new Date().getFullYear()} SUIIT Library Management System. All rights reserved.</p>
          <p className="text-light">Visit our official website: <a href="http://www.suiit.ac.in" target="_blank" rel="noopener noreferrer" className="text-light">www.suiit.ac.in</a></p>
        </div>
        
      </div>
      
 

</footer>

  );
};

export default Footer;


