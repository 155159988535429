import React, { useEffect, useState, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes,useLocation} from 'react-router-dom';
import Navbar from './pages/Navbar';
import {  database } from './firebaseConfig'; // Firebase config
import { ref, onValue } from 'firebase/database'; // Firebase database methods
import { saveDataToDB, getDataFromDB } from './indexedDB'; // IndexedDB utility
import './App.css'; // Ensure your CSS file is imported
import Footer from './pages/footer';
import Sitemap from "./components/sitemap";
import Mobnov from './pages/Mobnov';
import LibraryPage from './Skeleton/LibraryPage';
import PrivateRoute from './PrivateRoute'
import { trackUniqueVisitor } from './pages/visitorTracker';
import Foot from './pages/foot.js'

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); 
  }, [pathname]);

  return null;
};


// Lazy loading components
const AdminPage = lazy(() => import('./pages/AdminPage'));
const StudentPage = lazy(() => import('./pages/StudentPage'));
const FacultyPage = lazy(() => import('./pages/FacultyPage'));
const NoticesPage = lazy(() => import('./pages/NoticePage'));
const NoticeDetails = lazy(() => import('./pages/NoticeDetails'));
const RequiredBookPage = lazy(() => import('./pages/RequiredBookPage'));
const Contact = lazy(() => import('./pages/contact'));
const AddBook = lazy(() => import('./components/AddBook'));
const Scanner = lazy(() => import('./components/scanner'));
const BookListPage = lazy(() => import('./pages/BookListPage'));
const IssuedBooks = lazy(() => import('./pages/IssuedBook'));
const HomePage = lazy(() => import('./pages/HomePage'));
const AddNotice = lazy(() => import('./pages/AddNotice'));
const UserRequestedBooksPage = lazy(() => import('./pages/UserRequestedBooksPage'));
const UserList = lazy(() => import('./pages/UserList'));
const UserChat = lazy(() => import('./pages/UserChat'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const TermsandConditions = lazy(() => import('./pages/TermsandConditions'));
const CancellationandRefund = lazy(() => import('./pages/CancellationandRefund'));
const ShippingandDelivery = lazy(() => import('./pages/ShippingandDelivery'));
const ContactUs = lazy(() => import('./pages/ContactUs'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const Login = lazy(() => import('./pages/login'));
const About = lazy(() => import('./pages/AboutPage'));
const AboutSUIIT = lazy(() => import('./pages/AboutSUIIT'));
const Gallery = lazy(() => import('./components/Gallery'));
const AddToGallery = lazy(() => import('./components/AddToGallery'));
const AddBookSoftware = lazy(() => import('./components/AddBookSoftware'));
const AddBookPage = lazy(() => import('./components/AddBookPage'));
const FacultyAddBookSoftware = lazy(() => import('./components/FacultyAddBookSoftware'));
const BookSoftcopy = lazy(() => import('./components/BookSoftcopy'));
const AddJournals = lazy(() => import('./pages/AddJournalsPage'));
const Journals = lazy(() => import('./pages/Journals'));
const JournalDetails = lazy(() => import('./pages/JournalDetails'));
const VisitorDetailsPage = lazy(() => import('./pages/VisitorDetailsPage'));
const FeedbackForm = lazy(() => import('./pages/FeedbackForm'));
const AdminLibraryEvents =lazy(() =>import('./pages/AdminLibraryEvents'));
const LibraryEvents =lazy(() =>import('./pages/LibraryEvents'));
const LibraryEventsDetails =lazy(() =>import('./pages/LibraryEventsDetails'));
const ProfileDetails =lazy(() =>import('./pages/ProfileDetails'));
const ProfileManager =lazy(() =>import('./pages/ProfileManager'));
const ProfileList =lazy(() =>import('./pages/ProfileList'));
const OSHEC =lazy(() =>import('./pages/OSHEC'));
const BarcodeGenerator = lazy(()=> import('./components/BarcodeGenerator'));
const SupportPage =lazy(() =>import('./pages/SupportPage'));
const UserDetails =lazy(() =>import('./pages/UserDetails'));
const App = () => {

  const [books, setBooks] = useState([]);
  const [issuedBooks, setIssuedBooks] = useState([]);

  // Function to fetch books from Firebase or IndexedDB


    useEffect(() => {
    // Call the function to track the visitor when the app loads
    trackUniqueVisitor();
  }, []);

  const fetchBooks = async () => {
    try {
      const booksRef = ref(database, 'books/');
      onValue(booksRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const bookArray = Object.keys(data).map((key) => ({
            id: key,
            ...data[key],
          }));
          setBooks(bookArray);
          saveDataToDB('books', bookArray); // Save to IndexedDB
          localStorage.setItem('books', JSON.stringify(bookArray)); // Save to local storage
        }
      });
    } catch (error) {
      console.log('Offline: Fetching books from IndexedDB');
      const offlineBooks = await getDataFromDB('books');
      setBooks(offlineBooks);
    }
  };

  // Function to fetch issued books from Firebase or IndexedDB
  const fetchIssuedBooks = async () => {
    try {
      const issuedRef = ref(database, 'issues/');
      onValue(issuedRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const issuedArray = Object.keys(data).map((key) => ({
            id: key,
            ...data[key],
          }));
          setIssuedBooks(issuedArray);
          saveDataToDB('issues', issuedArray); // Save to IndexedDB
          localStorage.setItem('issuedBooks', JSON.stringify(issuedArray)); // Save to local storage
        }
      });
    } catch (error) {
      console.log('Offline: Fetching issued books from IndexedDB');
      const offlineIssuedBooks = await getDataFromDB('issues');
      setIssuedBooks(offlineIssuedBooks);
    }
  };

  useEffect(() => {
    const offlineBooks = localStorage.getItem('books');
    if (offlineBooks) {
      setBooks(JSON.parse(offlineBooks));
    } else {
      fetchBooks(); // Fetch books when app loads
    }

    const offlineIssuedBooks = localStorage.getItem('issuedBooks');
    if (offlineIssuedBooks) {
      setIssuedBooks(JSON.parse(offlineIssuedBooks));
    } else {
      fetchIssuedBooks(); // Fetch issued books when app loads
    }
  }, []);

  return (
    <Router>
    <ScrollToTop/>
     
      <div>
  {/* Div for Navbar - Visible on medium and larger screens (PC) */}
  <div className="d-none d-md-block">
    <Navbar/>
   
   
  </div>

  {/* Div for Mobnov - Visible on small screens (Mobile) */}
  <div className="d-block d-md-none" >
    <Mobnov />
  </div>
  

</div>

        <Suspense fallback={<div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}><LibraryPage/></div>
}>
         <Routes>
  <Route path="/admin" element={<PrivateRoute allowedRole="admin" redirectTo="/"><AdminPage issuedBooks={issuedBooks} /></PrivateRoute>} />
  <Route path="/admin/AddBook" element={<PrivateRoute allowedRole="admin" redirectTo="/student"><AddBook /></PrivateRoute>} />
  <Route path="/admin/AddLibraryEvents" element={<PrivateRoute allowedRole="admin" redirectTo="/student"><AdminLibraryEvents/></PrivateRoute>} />
  <Route path="/admin/AddBookPage" element={<PrivateRoute allowedRole="admin" redirectTo="/student"><AddBookPage /></PrivateRoute>} />
  <Route path="/admin/user-requests" element={<PrivateRoute allowedRole="admin" redirectTo="/student"><UserRequestedBooksPage /></PrivateRoute>} />
  <Route path="/admin/visitor-details" element={<PrivateRoute allowedRole="admin" redirectTo="/student"><VisitorDetailsPage /></PrivateRoute>} />
  <Route path="/admin/add-journals" element={<PrivateRoute allowedRole="admin" redirectTo="/student"><AddJournals /></PrivateRoute>} />
  <Route path="/journals/:id" element={<JournalDetails />} />
  <Route path="/journals" element={<Journals />} />
  <Route path="/student" element={<PrivateRoute allowedRole="student" redirectTo="/login"><StudentPage books={books} /></PrivateRoute>} />
  <Route path="/faculty" element={<PrivateRoute allowedRole="faculty" redirectTo="/student"><FacultyPage /></PrivateRoute>} />
  <Route path="/notices" element={<NoticesPage />} />
  <Route path="/notice/:noticeId" element={<NoticeDetails />} />
  <Route path="/login" element={<Login />} />
  <Route path="/about" element={<About />} />
  <Route path="/aboutSUIIT" element={<AboutSUIIT />} />
  <Route path="/scanner" element={<Scanner />} />
  <Route path="/required-books" element={<RequiredBookPage />} />
  <Route path="/contact" element={<Contact />} />
  <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
  <Route path="/TermsandConditions" element={<TermsandConditions />} />
  <Route path="/CancellationandRefund" element={<CancellationandRefund />} />
  <Route path="/ShippingandDelivery" element={<ShippingandDelivery />} />
  <Route path="/ContactUs" element={<ContactUs />} />
  <Route path="/gallery" element={<Gallery />} />
  <Route path="/libraryEvents" element={<LibraryEvents/>} />
  <Route path="/FeedbackForm" element={<FeedbackForm />} />
  <Route path="/books" element={<BookListPage books={books} />} />
  <Route path="/admin/issuedBooks" element={<PrivateRoute allowedRole="admin" redirectTo="/"><IssuedBooks issuedBooks={issuedBooks} /></PrivateRoute>} />
  <Route path="/admin/add-notice" element={<PrivateRoute allowedRole="admin" redirectTo="/student"><AddNotice /></PrivateRoute>} />
  <Route path="/admin/UserDetails" element={<PrivateRoute allowedRole="admin" redirectTo="/student"><UserDetails /></PrivateRoute>} />
  <Route path="/admin/users" element={<PrivateRoute allowedRole="admin" redirectTo="/student"><UserList /></PrivateRoute>} />
  <Route path="/user-chat/:userEmail" element={<PrivateRoute allowedRole="admin" redirectTo="/student"><UserChat /></PrivateRoute>} />
  <Route path="/admin/AddToGallery" element={<PrivateRoute allowedRole="admin" redirectTo="/student"><AddToGallery /></PrivateRoute>} />
  <Route path="/admin/AddBookSoftware" element={<PrivateRoute allowedRole="admin" redirectTo="/student"><AddBookSoftware /></PrivateRoute>} />
  <Route path="/faculty/FacultyAddBookSoftware" element={<PrivateRoute allowedRole="faculty" redirectTo="/student"><FacultyAddBookSoftware /></PrivateRoute>} />
  <Route path="/BookSoftcopy" element={<BookSoftcopy />} />
  <Route path="/BarcodeGenerator" element={<BarcodeGenerator />} />
  <Route path="/libraryEvents/:eventId" element={<LibraryEventsDetails />} />
  <Route path="/profile/:profileId" element={<ProfileDetails />} />
  <Route path="/profiles" element={<ProfileList />} />
  <Route path="/ProfileManager" element={<PrivateRoute allowedRoles={["admin", "faculty"]} redirectTo="/student"><ProfileManager/></PrivateRoute>} />
  <Route path="/Sitemap" element={<Sitemap />} />
  <Route path="/OSHEC" element={<OSHEC/>} />
  <Route path="/support" element={<SupportPage />} />
  <Route path="/" element={<HomePage />} />
  <Route path="*" element={<NotFoundPage />} />

</Routes>
 
        </Suspense>
        <Footer/>
     <Foot/>
    </Router>
  );
};

export default App;