import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import LibraryPage from './Skeleton/LibraryPage';
import suiit from './pages/images/suiit.png';

const GITHUB_API_URL = "https://api.github.com/repos/aediain/SUIIT-private/contents/Admin/password.json";
const AUTH_HEADERS = {
  Authorization: `token ${process.env.REACT_APP_GIT_HUB_API_KEY}`,
  Accept: "application/vnd.github.v3+json",
};

const PrivateRoute = ({ children, allowedRole, redirectTo = '/' }) => {
  const [user, setUser] = useState(() => JSON.parse(localStorage.getItem("user")) || null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isFaculty, setIsFaculty] = useState(false);
  const [isStudent, setIsStudent] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [hovered, setHovered] = useState(false);
  const [storedUserId, setStoredUserId] = useState('');
  const [storedPassword, setStoredPassword] = useState('');

  useEffect(() => {
    const fetchCredentials = async () => {
      try {
        const response = await fetch(GITHUB_API_URL, {
          headers: AUTH_HEADERS,
        });
        const data = await response.json();
        const decodedContent = atob(data.content); // Decode base64 content
        const { userId, password } = JSON.parse(decodedContent);
        setStoredUserId(userId);
        setStoredPassword(password);
      } catch (error) {
        console.error('Error fetching credentials:', error);
      }
    };

    fetchCredentials();
  }, []);

  useEffect(() => {
    const storedAuth = localStorage.getItem('isAuthenticated');
    if (storedAuth === 'true') {
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();
    if (userId === storedUserId && password === storedPassword) {
      setIsAuthenticated(true);
      localStorage.setItem('isAuthenticated', 'true'); // Store auth state in localStorage
    } else {
      alert('Invalid credentials');
    }
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        // Set user details and email verification status
        setUser(currentUser);
        setEmailVerified(currentUser.emailVerified);

        // Set roles based on the provided conditions
        const isAdmin = (currentUser.email === process.env.REACT_APP_ADMIN_EMAIL_1 || currentUser.email === "library@suiit.ac.in") && currentUser.emailVerified;
        const isFaculty = currentUser.email.endsWith('@suiit.ac.in') && !/\d/.test(currentUser.email) && currentUser.emailVerified;
        const isStudent = currentUser.email.endsWith('@suiit.ac.in') && /\d/.test(currentUser.email) && currentUser.emailVerified;

        setIsAdmin(isAdmin);
        setIsFaculty(isFaculty);
        setIsStudent(isStudent);
      } else {
        setUser(null);
        setIsAdmin(false);
        setIsFaculty(false);
        setIsStudent(false);
        setEmailVerified(false);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  if (allowedRole === 'admin' && !isAuthenticated) {
    return (
      <div className="container-fluid mt-0" // Use container-fluid instead of container
        style={{
          fontSize: "1.1rem",
          lineHeight: "1.8",
          color: "#333",
          fontFamily: 'revert-layer',
          backgroundImage: `url(${suiit})`,
          backgroundSize: "cover", // Ensures the image covers the entire section
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          opacity: 1,
          position: "relative",
          padding: 0, // Removes any padding
          margin: 0, // Removes any margin
          overflowX: "hidden", // Prevents horizontal overflow
          overflowY: "auto",
          height: '100vh',
        }}>
        <div className="d-flex justify-content-center align-items-center" style={{ backgroundColor: "rgba(0, 0, 0, 0)", paddingTop: '20vh' }}>
          <div className="card p-4" style={{ width: '350px', borderRadius: '15px', boxShadow: '0px 1px 10px rgb(0, 0, 0)', backgroundColor: "rgba(0, 0, 0, 0)", backdropFilter: 'blur(10px)' }}>
            <h2
              className="text-center"
              style={{ fontSize: '2.5rem', fontWeight: '600', letterSpacing: '2px', textShadow: '0px 1px 10px rgba(0, 0, 0, 1)', marginBottom: '20px', fontFamily: "'Montserrat', sans-serif", color: "white" }}
            >
              Admin Panel
            </h2>
            <form onSubmit={handleLogin}>
              <div className="mb-3">
                <div className="input-group">
                  <span className="input-group-text bg-warning"><i className="bi bi-person"></i></span>
                  <input
                    type="text"
                    placeholder="User ID"
                    value={userId}
                    onChange={(e) => setUserId(e.target.value)}
                    required
                    className="form-control"
                    style={{ borderRadius: '10px' }}
                  />
                </div>
              </div>
              <div className="mb-3">
                <div className="input-group">
                  <span className="input-group-text bg-warning"><i className="bi bi-key"></i></span>
                  <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="form-control"
                    style={{ borderRadius: '10px' }}
                  />
                </div>
              </div>
              <button type="submit" className="btn btn-outline-light w-100 mt-2 d-flex justify-content-center align-items-center"
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                style={{
                  boxShadow: "0 4px 25px rgb(0, 0, 0)",
                  textShadow: "0 4px 95px rgb(0, 0, 0)",
                  background: hovered
                    ? "linear-gradient(90deg, rgb(91, 196, 11), rgb(144, 238, 144))"
                    : "linear-gradient(90deg, rgb(255, 0, 0), rgb(255, 69, 0))",
                  backgroundSize: "200% 200%",
                  transition: "background-position 1.5s ease-in-out",
                  backgroundPosition: hovered ? "right center" : "left center",
                  color: "white",
                  border: "none",
                  cursor: "pointer",
                }}>
                <div style={{ fontSize: '19px' }}>
                  <i className="bi bi-box-arrow-in-right"></i> <spin></spin>
                  <span className="fw-"> Login as Admin</span>
                </div>
              </button>
            </form>
            <div className="mt-3 d-flex justify-content-between">
              <small>
                <a href="./student" style={{ textDecoration: 'none', color: 'white' }}>Not an Admin?</a>
              </small>
              <small>
                <a href="/" style={{ textDecoration: 'none', color: 'white' }}>Home</a>
              </small>
            </div>
            <div className="text-center mt-4" style={{ fontSize: '12px', color: 'white' }}>
              <p>© {new Date().getFullYear()} SUIIT Library Management System. All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (loading) {
    return <div><LibraryPage /></div>;
  }

  if (!user || !emailVerified) {
    return <Navigate to="/login" />;
  }

  // Additional check for admin role
  if (allowedRole === 'admin' && !isAdmin) {
    return <Navigate to={redirectTo} />;
  }

  if (allowedRole === 'faculty' && !isFaculty) {
    return <Navigate to={redirectTo} />;
  }

  if (allowedRole === 'student' && !isStudent) {
    return <Navigate to={redirectTo} />;
  }

  return children;
};

export default PrivateRoute;